<script setup>
    import HighlightContainer from '@/components/HighlightContainer.vue';
    import CardsWithDate from '@/components/CardsWithDate.vue';
    import EducationInfo from '@/components/EducationInfo.vue';
    import CourseInfo from '@/components/CourseInfo.vue';
    import EducationData from '@/assets/data/educationData.json';
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 order-1 mb-4 mb-sm-0">
            <HighlightContainer name="Completed courses">
                <CardsWithDate ref="card" class="course" v-for="course in EducationData.courses" :key="course"
                    :date="course.date">
                    <CourseInfo v-for="name in course.names" :key="name" :name="name" />
                </CardsWithDate>
            </HighlightContainer>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 order-0 mb-4 mb-sm-0">
            <HighlightContainer name="Education">
                <CardsWithDate ref="card" class="education" v-for="highlight in EducationData.education"
                    :key="highlight" :date="highlight.date" :place="highlight.place">
                    <EducationInfo :degree="highlight.degree" :university="highlight.university"
                        :description="highlight.description" />
                </CardsWithDate>
            </HighlightContainer>
            <HighlightContainer name="Awards">
                <CardsWithDate ref="card" class="award" v-for="award in EducationData.awards" :key="award"
                    :date="award.date">
                    <CourseInfo v-for="name in award.names" :key="name" :name="name" />
                </CardsWithDate>
            </HighlightContainer>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.$nextTick(() => {
                const cards = this.$refs.card;
                const accordionItems = Array.from(cards);

                accordionItems.forEach((item) => {
                    const elem = item.$el;
                    if (elem) {
                        //console.log(elem);
                        this.cardHoverTimer(elem);
                    }
                });
            });
        },
        methods: {
            cardHoverTimer(element) {
                let timer;
                let eventTitle = '';
                let cardTitle = '';

                if (element) {
                    if (element.classList.contains('course')) {
                        eventTitle = 'Course Card Hover';
                        cardTitle = element.querySelector('.highlight-left > .date').textContent;
                    } else if (element.classList.contains('education')) {
                        eventTitle = 'Education Card Hover';
                        cardTitle = element.querySelector('.job-title').textContent;
                    } else if (element.classList.contains('award')) {
                        eventTitle = 'Award Card Hover';
                        cardTitle = element.querySelector('.highlight-left > .date').textContent;
                    }
                }

                element.addEventListener('mouseenter', () => {
                    timer = setTimeout(() => {
                        //console.log("Hovered for 1.5 seconds");
                        //console.log(eventTitle);
                        //console.log(cardTitle);

                        this.$gtm.push({
                            event: eventTitle,
                            cardTitle: cardTitle,
                        });
                    }, 1500);
                });

                element.addEventListener('mouseleave', () => {
                    clearTimeout(timer);
                });
            }
        }
    };
</script>