<template>
    <h4 class="job-title">{{ degree }}</h4>
    <h4 class="university-title">
        {{ university }}
    </h4>
    <h5 class="description">{{ description }}
    </h5>
</template>

<script>
    export default {
        props: {
            degree: String,
            university: String,
            description: String,
        },
    };
</script>


<style scoped>
    .job-title {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.25;
        color: var(--main-text-color);
        margin: 0;
    }

    .university-title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25;
        color: var(--second-text-color);
        margin: 0;
    }

    .description {
        font-size: .975rem;
        line-height: 1.5;
        color: var(--second-text-color);
        margin-top: 1rem;
        margin-bottom: 0;
    }
</style>
