<template>
    <div class="warningCard" :class="{ 'fade-out': isDeleting }">
        <p>{{ title }}</p>
        <i @click="dismissCard" class='bx bx-x'></i>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
        },
        data() {
            return {
                isDeleting: false
            };
        },
        methods: {
            dismissCard() {
                this.isDeleting = true;
                setTimeout(() => {
                    this.$el.remove();
                }, 350);
            }
        }
    };
</script>


<style scoped>
    .warningCard {
        background: var(--warning-card-background-color);
        padding: .6rem 1rem;

        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: var(--warning-card-border-color);
        vertical-align: middle;

        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 0.8rem;

        /* transition: opacity 0.15s ease-out; */
    }

    @keyframes container-animation {
        from {
            opacity: 1;
            filter: blur(0px);
            transform: translateY(0%);
        }

        to {
            opacity: 0;
            filter: blur(10px);
            transform: translateY(-20%);
        }

    }

    .fade-out {
        animation: container-animation 0.3s forwards;
    }


    .warningCard p {
        font-size: .7rem;
        font-weight: 600;
        line-height: 1rem;
        color: var(--main-text-color);
        text-transform: uppercase;
        letter-spacing: .025em;
        margin: 0;
    }

    .warningCard i {
        font-size: 1.2rem;
        height: auto;
        width: auto;
        color: var(--warning-card-icon-color);
        cursor: pointer;

        border-radius: 4px;

    }

    .warningCard i:hover {
        background-color: var(--warning-card-icon-hover-color);
    }
</style>