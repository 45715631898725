<script setup>
	import { RouterLink, RouterView } from 'vue-router'
</script>

<template>
	<div class="cursor"></div>
	<div class="container">
		<div class="about-me mt-5">
			<div class="row justify-content-lg-center align-items-center">
				<div class="col-12 col-sm-12 col-md-12 col-lg-6 mb-5 mb-auto mt-auto">
					<div class="row align-items-center">
						<div class="col-5 col-sm-4 col-md-3 col-lg-4">
							<img src="/img/avatar.jpg" />
						</div>
						<div class="col-5 col-sm-8 col-md-8 col-lg-8">
							<h1>Anton Bagin</h1>
							<h3>Data scientist | ML engineer</h3>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-12 col-md-12 col-lg-5 mb-auto mt-5 mt-lg-auto">
					<p>
						{{ headerText }}
					</p>
				</div>
			</div>
		</div>

		<nav class="tabs-bar container-animation">
			<RouterLink to="/">About me</RouterLink>
			<RouterLink to="/experience">Experience</RouterLink>
			<RouterLink to="/education">Education</RouterLink>
			<RouterLink to="/talks">Talks</RouterLink>
		</nav>

		<RouterView class="container-animation" />

		<div class="contact-widget">
			<a href="mailto: anton.s.bagin@gmail.com?subject=Get In Touch mail" @click="openEmail_GetInTouch">
				Get in touch
			</a>
			<a target="_blank" href="https://linkedin.com/in/anton-bagin/" @click="openLinkedIn_GetInTouch">
				<img src="/img/linkedin.png" loading="lazy" height="20" width="20" />
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				headerText: ""
			};
		},
		watch: {
			'$route.path': function (newPath) {
				this.setHeaderText(newPath);
			}
		},
		methods: {
			setHeaderText(path) {
				switch (path) {
					case '/education':
						this.headerText = 'Avid learner with a proven ability to master new technologies, believing success comes from hard work, continuous skill development, and perseverance. Strong advocate for combining data-driven approaches with creative thinking to optimize processes and deliver impactful results.';
						break;
					case '/talks':
						this.headerText = 'I’m experienced in delivering public speeches, confidently presenting complex technical topics to diverse audiences. In addition, I have a track in tech teaching and mentoring, helping others develop their skills and grow in their careers.';
						break;
					default:
						this.headerText = 'Highly motivated and diligent data scientist with 20+ years in tech, including 10+ years in data-related roles. Proficient in Python and SQL, with programming background, deep core-ML knowledge and experience.';
						break;
				}
			},
			openEmail_GetInTouch() {
				this.$gtm.push({
					event: "Get In Touch Click",
					get_in_touch_type: "By email",
				});
			},
			openLinkedIn_GetInTouch() {
				this.$gtm.push({
					event: "Get In Touch Click",
					get_in_touch_type: "by LinkedIn",
				});
			}

		},
		mounted() {
			this.setHeaderText(this.$route.path);

			const cursorElement = document.querySelector('.cursor')

			window.addEventListener('mousemove', (event) => {
				if (window.innerWidth >= 1024) {
					cursorElement.style.setProperty('--gradient-x', `${event.x}px`)
					cursorElement.style.setProperty('--gradient-y', `${event.y}px`)
				}
			});
		},
	}
</script>


<style scoped>

	.cursor {
		--gradient-x: 0;
		--gradient-y: 0;

		z-index: 30;
		inset: 0;
		position: absolute;
		transition-duration: .3s;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
		transition-timing-function: cubic-bezier(.4, 0, .2, 1);
		pointer-events: none;
		filter: blur(8px);

		background: radial-gradient(600px at var(--gradient-x) var(--gradient-y), var(--cursor-color), transparent 80%);
	}

	@media (min-width: 1024px) {
		.cursor {
			position: fixed;
		}
	}

	.pointer-events-none {
		pointer-events: none;
	}

	.container {
		margin-bottom: 5rem;
	}


	/* animation */

	@keyframes container-animation {
		from {
			opacity: 0;
			filter: blur(10px);
			transform: translateY(20%);
		}

		to {
			opacity: 1;
			filter: blur(0px);
			transform: translateY(0%);
		}
	}

	.container-animation {
		animation: container-animation 0.5s forwards;
	}

	/* ABOUT ME */

	.about-me img {
		border-radius: 50%;
		width: 152px;
		height: auto;
	}

	.about-me h1 {
		font-size: 3rem;
		color: var(--main-text-color);
		font-weight: 500;
	}

	.about-me h3 {
		color: var(--main-text-color);
		font-size: 1.25rem;
		font-weight: 500;
	}

	.about-me p {
		font-size: 20px;
		color: var(--second-text-color);
		font-weight: 400;
		text-align: center;
	}

	/* GET IN TOUCH FIXED WIDGET */

	.contact-widget {
		position: fixed;
		bottom: 1.5em;
		background-color: var(--contact-widget-background-color);
		border-radius: 12px;
		border-color: var(--contant-widget-border-color);
		border-style: solid;
		border-width: 1px;
		display: flex;
		padding: 0.5rem;
		gap: 0.4rem;
	}

	.contact-widget a {
		color: var(--main-text-color);
		text-decoration: none;
		border-radius: 4px;
		padding: 0.2rem 0.3rem;
		font-weight: 600;
		display: flex;
		align-items: center;
	}

	.contact-widget a:hover {
		background-color: var(--contact-widget-hover-color);
	}

	/* TABS BAR */

	.tabs-bar {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		margin-top: 2rem;
		margin-bottom: 3rem;
		flex-wrap: wrap;
	}

	.tabs-bar a {
		color: var(--main-text-color);
		font-size: 1.2rem;
		font-weight: 600;
		text-decoration: none;
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
		transition: transform 150ms, background 150ms;
		transform: scale(1) translate3d(0px, 0px, 0px) perspective(1px);
	}

	.tabs-bar a:active:not(.router-link-active) {
		transform: scale(0.96) translate3d(0px, 0px, 0px) perspective(1px) !important;
	}

	.tabs-bar a:hover:not(.router-link-active) {
		background-color: var(--tab-bar-hover-color);
		cursor: pointer;
		transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
	}

	.router-link-active {
		background-color: var(--main-text-color);
		color: var(--tab-bar-hover-color) !important;
	}
</style>
