<template>
    <div>
        <h1>404</h1>
        <p>Page not found</p>
        <router-link to="/">Go Home</router-link>
    </div>
</template>

<style scoped>
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 2rem;
    }

    a {
        position: relative;
        width: fit-content;

        display: flex;
        align-items: center;

        padding: .3rem .7rem;
        border-radius: 8px;
        border-color: var(--cards-border-color);
        background-color: var(--link-container-color);
        color: var(--main-text-color);

        text-decoration: none;

        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s;
    }

    a:hover {
        background-color: var(--link-hover-color);
        fill: var(--cards-container-color);
    }

</style>