// https://github.com/markdown-it/markdown-it

<script setup>
    import MarkdownIt from "markdown-it";
    import BlurLoadingContainer from '@/components/BlurLoadingContainer.vue';
</script>

<template>
    <BlurLoadingContainer ref="mdBlur" skeletonHeight="80vw">
        <div v-html="renderedMarkdown"></div>
    </BlurLoadingContainer>
</template>

<script>
    export default {
        props: {
            source: String,
        },
        async beforeCreate() {
            const res = await fetch(this.source)
                .then(response => response.text())

            //console.log(res);
            if (res) {
                this.markdownText = res;
                this.$refs.mdBlur.loaded();
            }
        },
        computed: {
            renderedMarkdown() {
                const md = new MarkdownIt({
                    html: true,
                    linkify: true,
                    typographer: true,
                });
                return md.render(this.markdownText);
            },
        },
        data() {
            return {
                markdownText: '',
                md: '',
            };
        },
    };
</script>