<template>
    <div class="skills-section">
        <div class="live">
            <span class="live-start-span"></span>
            <header>
                {{ name }}
            </header>
            <span class="live-end-span"></span>
        </div>
        <div class="skills">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: String,
        }
    };
</script>

<style scoped>
    .live {
        display: flex;
        align-items: center;
    }

    .live header {
        font-size: .975rem;
        display: flex;
        align-items: center;
        color: var(--second-text-color);
        text-transform: uppercase;
        letter-spacing: .025em;
    }

    .live-start-span {
        height: 1px;
        background: var(--skills-header-line-color);
        width: 5%;
        margin-right: 0.5rem;
    }

    .live-end-span {
        height: 1px;
        background: var(--skills-header-line-color);
        flex-grow: 1;
        margin-left: 0.7rem;
    }

    .skills {
        display: flex;
        flex-wrap: wrap;
    }
</style>