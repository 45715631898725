import '/node_modules/bootstrap/dist/css/bootstrap-grid.min.css';
import '/node_modules/bootstrap/dist/css/bootstrap-reboot.min.css';
import '/node_modules/bootstrap/dist/css/bootstrap-utilities.min.css';

import './assets/css/index.css';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { vTooltip, Tooltip, options } from 'floating-vue';
import 'floating-vue/dist/style.css';

import * as Sentry from "@sentry/vue";
import { createGtm } from '@gtm-support/vue-gtm';

import { createManager } from '@vue-youtube/core';

options.disposeTimeout = 100;
options.themes.myTheme = {
    '$extend': 'tooltip',
    triggers: ['hover', 'click'],
    delay: {
        show: 350,
        hide: 350,
    },
    autoHide: true,
    placement: 'top',
};

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://db6f6dc42aa546d1993e2a762640f3fe@o4506094609563648.ingest.us.sentry.io/4507895948247040",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
    ],
    trackComponents: true,
    hooks: ["mount", "update", "unmount"],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost:5173',
        "antonbagin.com",
        /^\//,
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.directive('tooltip', vTooltip);
app.component('VTooltip', Tooltip);
app.use(router);

app.use(createManager());

app.use(
    createGtm({
        id: 'GTM-NN398GM7',
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    }),
);

app.mount('#app');