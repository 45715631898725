<script setup>
    import talksData from '@/assets/data/talksData.json';
    import HighlightContainer from '@/components/HighlightContainer.vue';
    import 'viewerjs/dist/viewer.css';
    import { component as Viewer } from "v-viewer";
    import MarkDownReader from '@/components/MarkDownReader.vue';
    import { YoutubeIframe } from '@vue-youtube/component';
    import BlurLoadingContainer from '@/components/BlurLoadingContainer.vue';
</script>

<template>
    <div v-if="destination" class="talk-container">
        <router-link to="/talks" class="talk-link">
            <div class="back-link">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="6 0 14 24">
                    <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                </svg>
                <p>Go Back</p>
            </div>
        </router-link>
        <div class="talk-content">
            <div class="row">
                <div class="col-12 info">
                    <h1>{{ destination.title }}</h1>
                    <h4>{{ destination.date }}</h4>
                </div>
                <div v-if="destination.video_id || destination.downloads || destination.images"
                    class="col-12 col-sm-12 col-md-12 col-lg-6 video">
                    <div v-if="destination.video_id" class="yt-video-container mb-3">
                        <BlurLoadingContainer ref="ytBlur">
                            <youtube-iframe :video-id="destination.video_id" @ready="onReady"
                                @stateChange="onStateChange" width="100%" height="100%" style="border-radius: 12px;" />
                        </BlurLoadingContainer>
                    </div>
                    <div v-if="!destination.video_id && destination.images" class="preview-img mb-3">
                        <BlurLoadingContainer ref="titleImageBlur" skeletonHeight="25rem">
                            <img v-if="destination.images[0]" :src="destination.images[0]"
                                @load="$refs.titleImageBlur.loaded();" />
                        </BlurLoadingContainer>
                    </div>
                    <HighlightContainer v-if="destination.downloads" class="downloads" name="Downloads">
                        <a ref="download" v-for="dl in destination.downloads" :key="dl" :href="dl.url" target="_blank">
                            {{ dl.name }}
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path
                                    d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z">
                                </path>
                                <path
                                    d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z">
                                </path>
                            </svg>
                        </a>
                    </HighlightContainer>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mt-3 mt-lg-0 description">
                    <MarkDownReader :source="destination.markdown" />
                </div>
                <div v-if="destination.images" class="col-12 mt-5">
                    <HighlightContainer name="Images">
                        <BlurLoadingContainer ref="imagesBlur" skeletonHeight="10rem">
                            <viewer :options="options" :images="destination.images" @inited="inited"
                                class="image-viewer" ref="viewer">
                                <template #default="scope">
                                    <img ref="image" v-for="src in scope.images" class="image" :src="src" :key="src">
                                </template>
                            </viewer>
                        </BlurLoadingContainer>
                    </HighlightContainer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // будущий я.. если ты будешь пытаться в этом разобраться, то ебать ты лох :) ну рили, я тут такое дерьмо понаписал, сосешь)
    export default {
        async mounted() {
            const tabbar = document.querySelector('.tabs-bar')
            if (tabbar) {
                tabbar.style.display = 'none'
            }

            setTimeout(() => {
                //console.log("Hovered for 1.5 seconds");
                //console.log(cardTitle);

                const page_title = this.destination.title;

                this.$gtm.push({
                    event: 'Talk Page Opened',
                    cardTitle: page_title,
                });
            }, 1500);

            this.$nextTick(() => {
                document.title = "Anton Bagin | " + this.destination.title || "Anton Bagin - Talk";

                if (this.destination.images) {
                    this.addEventToViewer();

                    const images = this.$refs.image;
                    const imagesItems = Array.from(images);

                    imagesItems.forEach((item) => {
                        let timer;

                        const title = item.src;
                        item.addEventListener('click', () => {
                            this.$gtm.push({
                                event: "Talk Page Image Opened",
                                cardTitle: title,
                            });
                        });

                        item.addEventListener('mouseenter', () => {
                            timer = setTimeout(() => {
                                //console.log("Hovered for 1.5 seconds");
                                //console.log(cardTitle);

                                this.$gtm.push({
                                    event: 'Talk Page Image Hovered',
                                    cardTitle: title,
                                });
                            }, 1500);
                        });

                        item.addEventListener('mouseleave', () => {
                            clearTimeout(timer);
                        });

                    });
                }

                const downloads = this.$refs.download;
                const downloadsItems = Array.from(downloads);

                downloadsItems.forEach((item) => {
                    const elem = item;
                    if (elem) {
                        //console.log(elem);
                        const href = item.href;
                        const title = item.innerText;
                        elem.addEventListener('click', () => {
                            this.$gtm.push({
                                event: "Talk Page Download Link Opened",
                                cardTitle: title,
                                cardURL: href,
                            });
                        });
                    }
                });
            });
        },
        unmounted() {
            const tabbar = document.querySelector('.tabs-bar')
            if (tabbar) {
                tabbar.style.display = 'flex'
            }
        },
        computed: {
            destinationId() {
                return this.$route.params.id
            },
            destination() {
                const destination = talksData.talks.find(destination => destination.id === this.destinationId)

                if (!destination) {
                    this.$router.push({ name: 'NotFound' })
                    return null
                }

                return destination
            },
        },
        methods: {
            inited(viewer) {
                const sd = Date.now()
                this.$viewer = viewer
                console.log('inited')

                const observer = new MutationObserver((mutationsList, observer) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            const viewerElement = document.querySelector('.image-viewer .image');

                            if (viewerElement) {
                                const ddd = Date.now() - sd
                                console.log('found .image-viewer .image');
                                console.log(ddd);
                                this.$refs.imagesBlur.loaded()

                                observer.disconnect();
                                break;
                            }
                        }
                    }
                });

                observer.observe(document.body, { childList: true, subtree: true });
            },
            addEventToViewer() {
                if (this.destination.images.length === 1) {
                    return;
                }
                const observer = new MutationObserver((mutationsList, observer) => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            const viewerElement = document.querySelector('.viewer-container');

                            if (viewerElement) {
                                console.log('found viewer-container');

                                const viewer_next_button = document.querySelector('.viewer-next');
                                if (viewer_next_button) {
                                    console.log('found viewer-next');
                                    viewer_next_button.addEventListener('click', async () => {
                                        const imgURl = await this.findImageURL();

                                        this.$gtm.push({
                                            event: "Talk Page Image Opened",
                                            cardTitle: imgURl,
                                        });
                                    });
                                }

                                const viewer_prev_button = document.querySelector('.viewer-prev');
                                if (viewer_prev_button) {
                                    console.log('found viewer-prev');
                                    viewer_prev_button.addEventListener('click', async () => {
                                        const imgURl = await this.findImageURL();

                                        this.$gtm.push({
                                            event: "Talk Page Image Opened",
                                            cardTitle: imgURl,
                                        });
                                    });
                                }

                                observer.disconnect();
                                break;
                            }
                        }
                    }
                });

                observer.observe(document.body, { childList: true, subtree: true });
            },
            async findImageURL() {
                let imageURL = '';

                await new Promise(resolve => {
                    const observer = new MutationObserver((mutationsList, observer) => {
                        for (const mutation of mutationsList) {
                            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                                const viewerActive = document.querySelector('.viewer-active');
                                if (viewerActive) {
                                    console.log('found viewer-active');

                                    const img = viewerActive.querySelector('img');

                                    if (img) {
                                        //console.log(img);
                                        imageURL = img.src;
                                    }

                                    observer.disconnect();
                                    resolve();
                                    break;
                                }
                            }
                        }
                    });

                    observer.observe(document.body, { attributes: true, subtree: true });
                });

                return imageURL;
            },
            // Youtube video tracking
            onReady() {
                console.log('YTIframeOnReady');
                if (this.destination.video_id) {
                    const elem = document.querySelector('.yt-video-container');
                    if (elem) {
                        let timer;

                        elem.addEventListener('mouseenter', () => {
                            if (!this.ytVideoStarted) {
                                timer = setTimeout(() => {
                                    //console.log("Hovered for 1.5 seconds");
                                    //console.log(element);
                                    this.$gtm.push({
                                        event: "Talk Page Youtube Video Hovered",
                                        cardURL: "https://youtube.com/watch?v=" + this.destination.video_id,
                                    });
                                }, 1500);
                            }
                        });

                        elem.addEventListener('mouseleave', () => {
                            clearTimeout(timer);
                        });
                        this.$refs.ytBlur.loaded();
                    }
                }
            },
            // https://developers.google.com/youtube/iframe_api_reference#onStateChange
            onStateChange(event) {
                console.log('YTIframeOnStateChange');
                if (!this.ytVideoStarted && event.data === 1) {
                    this.$gtm.push({
                        event: "Talk Page Youtube Video Opened",
                        cardURL: "https://youtube.com/watch?v=" + this.destination.video_id,
                    });
                    this.ytVideoStarted = true;
                }
            }
        },
        data() {
            return {
                ytVideoStarted: false,
                ytLoading: true,
                options: {
                    toolbar: {
                        zoomIn: 1,
                        zoomOut: 1,
                        oneToOne: 1,
                        reset: 0,
                        prev: 1,
                        play: 0,
                        next: 1,
                        rotateLeft: 0,
                        rotateRight: 0,
                        flipHorizontal: 0,
                        flipVertical: 0
                    }
                }
            };
        }
    }
</script>

<style scoped>
    .talk-container {
        display: flex;
        flex-direction: column;
        gap: .6rem;

        margin-top: 2rem;
    }

    .talk-content {
        padding: 1.2rem;
        border-radius: 8px;

        transition: 0.15s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    .talk-link {
        text-decoration: none;
        width: fit-content;
        display: block;
    }

    .talk-link:hover {
        cursor: pointer;

        +.talk-content {
            opacity: 0.3;
            background-color: var(--cards-container-color);
            box-shadow: inset 0 1px 0 0 var(--cards-border-color);
        }
    }

    .back-link {
        padding: .7rem 1.2rem;

        display: flex;
        align-items: center;

        width: fit-content;

        border-radius: 6px;

        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s;
    }

    .back-link:hover {
        background-color: var(--cards-container-color);
        box-shadow: inset 0 1px 0 0 var(--cards-border-color);
    }

    .back-link svg {
        width: 1rem;
        height: auto;

        fill: var(--main-text-color);
    }

    .back-link p {
        color: var(--main-text-color);
        font-weight: 500;
        margin: 0;
    }

    .yt-video-container {
        aspect-ratio: 16/9;
        width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .video .preview-img img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .video .downloads a {
        position: relative;
        width: fit-content;

        display: flex;
        align-items: center;

        padding: .3rem .7rem;
        border-radius: 8px;
        border-color: var(--cards-border-color);
        background-color: var(--link-container-color);
        color: var(--main-text-color);

        text-decoration: none;

        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s;
    }

    .video .downloads a:hover {
        background-color: var(--link-hover-color);
        fill: var(--cards-container-color);
    }

    .video .downloads a svg {
        width: 1.1rem;
        height: auto;
        fill: var(--main-text-color);
        margin-left: .3rem;
    }

    .info {
        margin-bottom: 2rem;
    }

    .info h1 {
        font-size: 2.3rem;
        font-weight: 600;
        color: var(--main-text-color);
        text-transform: capitalize;
        margin-bottom: .4rem;
        letter-spacing: .025em;
    }

    .info h4 {
        font-size: .85rem;
        color: var(--second-text-color);
        margin: 0;
        letter-spacing: .025em;
    }

    .description {
        font-size: 1rem;
        line-height: 1.4rem;
        color: var(--second-text-color);

        letter-spacing: .025em;
    }

    .image-viewer {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .image-viewer .image {
        border-radius: 8px;
        aspect-ratio: 4/3;
        height: auto;
        width: 200px;
        cursor: pointer;
        transition: 0.15s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    .image-viewer .image:hover {
        transform: scale(1.02) translate3d(0, 0, 0) perspective(1px);
        background-color: var(--cards-container-color);
    }

</style>
