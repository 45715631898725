<script setup>
	import VideoCard from '@/components/VideoCard.vue';
	import HighlightContainer from '@/components/HighlightContainer.vue';
	import talksData from '@/assets/data/talksData.json';
</script>

<template>
	<HighlightContainer name="Talks">
		<div class="row talks">
			<VideoCard ref="talkCard" v-for="talk in talksData.talks" :key="talk" :id="talk.id"
				:video_id="talk.video_id" :img="talk.img" :title="talk.title" :description="talk.description" />
		</div>
	</HighlightContainer>
</template>


<script>
	export default {
		mounted() {
			this.$nextTick(() => {
				const cards = this.$refs.talkCard;
				const accordionItems = Array.from(cards);

				accordionItems.forEach((item) => {
					const elem = item.$el;
					if (elem) {
						//console.log(elem);
						const title = item.title;
						this.cardHoverTimer(elem, title);
					}
				});
			});
		},
		methods: {
			cardHoverTimer(element, cardTitle) {
				//console.log('job hovered')
				let timer;

				// It's also triggered when user clicked on the card
				element.addEventListener('mouseenter', () => {
					timer = setTimeout(() => {
						//console.log("Hovered for 1.5 seconds");
						//console.log(cardTitle);

						this.$gtm.push({
							event: 'Talk Card Hover',
							cardTitle: cardTitle,
						});
					}, 1500);
				});

				element.addEventListener('mouseleave', () => {
					clearTimeout(timer);
				});

			},
		},
	};
</script>

<style scoped>
	.talks {
		row-gap: 0.5rem;
	}
</style>
