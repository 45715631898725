<template>
    <li>{{ name }}</li>

</template>

<script>
    export default {
        props: {
            name: String,
        },
    };
</script>


<style scoped>
    li {
        color: var(--second-text-color);
    }

    li::marker {
        color: var(--main-text-color);
    }
</style>
