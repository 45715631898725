<template>
    <h4 class="job-title">{{ jobTitle }}</h4>
    <h4 class="previous-job-title" v-for="job in previousJobTitle" :key="job">
        {{ job }}
    </h4>
    <p class="extra-info">{{ department }}</p>
    <h5 class="product"><span class="text-highlighter">Product:</span> {{ product }}
    </h5>
    <div class="cards mt-4">
        <div ref="card" class="card" v-for="card in cards" :key="card">
            <header class="card-header">
                <i class='bx bx-plus card-header-icon'></i>
                <h3 class="card-header-title">
                    {{ card.headerTitle }}
                </h3>
            </header>
            <div class="card-body">
                <ul class="card-description">
                    <li v-for="description in card.descriptions" :key="description">
                        {{ description }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            jobTitle: String,
            previousJobTitle: Array,
            department: String,
            product: String,
            cards: Array,
        },
        mounted() {
            this.addCardListeners();
        },
        methods: {
            addCardListeners() {
                this.$nextTick(() => {
                    const cards = this.$refs.card;
                    const accordionItems = Array.from(cards);

                    accordionItems.forEach((item) => {
                        //console.log(item);
                        this.cardHoverTimer(item);
                        const accordionHeader = item.querySelector('.card-header');

                        accordionHeader.addEventListener('click', () => {
                            accordionItems.forEach((item) => {
                                const isOpen = item.classList.contains('card-open');
                                //console.log('isOpen: ', isOpen);

                                if (isOpen) {
                                    this.toggleItem(item);
                                }
                            });

                            this.toggleItem(item);
                        });
                    });
                });
            },
            toggleItem(item) {
                const accordionContent = item.querySelector('.card-body')

                if (item.classList.contains('card-open')) {
                    accordionContent.removeAttribute('style')
                    item.classList.remove('card-open')
                } else {
                    //console.log('job card is opened')

                    accordionContent.style.height = accordionContent.scrollHeight + 'px'
                    item.classList.add('card-open')

                    const cardTitle = item.querySelector('.card-header-title').textContent;
                    //console.log(cardTitle.textContent);
                    if (cardTitle) {
                        this.$gtm.push({
                            event: 'Job Card Click',
                            cardTitle: cardTitle,
                        });
                    }
                }
            },
            cardHoverTimer(element) {
                let timer;

                const cardTitle = element.querySelector('.card-header-title').textContent;

                element.addEventListener('mouseenter', () => {
                    timer = setTimeout(() => {
                        //console.log("Hovered for 1.5 seconds");
                        //console.log(element);
                        this.$gtm.push({
                            event: 'Job Card Hover',
                            cardTitle: cardTitle,
                        });
                    }, 1500);
                });

                element.addEventListener('mouseleave', () => {
                    clearTimeout(timer);
                });
            }
        },
    };
</script>


<style scoped>
    .job-title {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.25;
        color: var(--main-text-color);
        margin: 0;
    }

    .previous-job-title {
        font-size: 1.15rem;
        font-weight: 500;
        line-height: 1.25;
        color: var(--second-text-color);
        margin: 0;
    }

    .product {
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.25;
        color: var(--second-text-color);
        margin-top: .75rem;
    }

    .text-highlighter {
        color: var(--main-text-color);
    }

    .extra-info {
        font-size: .875rem;
        line-height: 1.5;
        color: var(--second-text-color);
        margin-bottom: 0;
    }

    .cards {
        display: grid;
        row-gap: .75rem;
    }

    .card-header-title {
        font-size: var(--small-font-size);
        color: var(--cards-title-color);
        font-weight: 400;
        margin-top: .15rem;
        transition: .2s;
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .card-header {
        display: flex;
        column-gap: .5rem;
        padding: 1.25rem 1.25rem 1.25rem 1rem;
        cursor: pointer;
    }

    .card-description {
        padding: 0 1.25rem 1.25rem 3rem;
        font-size: var(--smaller-font-size);
        color: var(--cards-body-color);

        list-style-position: inside;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .card-description li {
        list-style-type: none;
    }

    .card-description li:before {
        content: '•';
        margin-right: 5px;
        color: var(--main-text-color);
    }

    .card-header-icon {
        font-size: 1.5rem;
        height: max-content;
        color: var(--cards-title-color);
        transition: .3s;
        margin-top: auto;
        margin-bottom: auto;
    }

    .card {
        box-shadow: 0 2px 6px rgba(38, 38, 38, 0.1);
        background-color: var(--cards-container-color);
        border-radius: .25rem;
        position: relative;
        transition: all .25s ease;
    }

    .card::after {
        content: '';
        background-color: var(--cards-left-little-thing-color);
        width: 5px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: .25rem 0 0 .25rem;
    }

    .card-body {
        overflow: hidden;
        height: 0;
        transition: all .25s ease;
    }

    /* Rotate icon and add font weight to titles */
    .card-open .card-header-icon {
        transform: rotate(45deg);
    }

    .card-open .card-header-title {
        font-weight: 600;
    }

    /* For medium devices */
    @media screen and (min-width: 576px) {
        .card-header {
            padding: 1.5rem;
        }

        .card-header-title {
            padding-right: 3.5rem;
        }

        .card-description {
            padding: 0 4rem 1.25rem 3.5rem;
        }
    }
</style>
