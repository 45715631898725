<script setup>
    import HighlightContainer from '@/components/HighlightContainer.vue';
    import CardsWithDate from '@/components/CardsWithDate.vue';
    import JobInfo from '@/components/JobInfo.vue';
    import SkillsSection from '@/components/SkillsSection.vue';
    import WarningCard from '@/components/WarningCard.vue';
    import ExperienceData from '@/assets/data/experienceData.json';
</script>

<template>
    <div class="row justify-content-center">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 order-1 mb-4 mb-sm-0">
            <HighlightContainer class="skills-stick" name="Skills">
                <WarningCard title="Hold on skill with a dot to get extra info!" />
                <ul class="skills-container">
                    <SkillsSection v-for="skill in ExperienceData.skills" :key="skill" :name="skill.name">

                        <!-- TODO: Currently function is called once. when user for the first time opens tooltip, other times analytics won't be sent -->
                        <!-- https://floating-vue.starpad.dev/api/ -->

                        <li v-for="skillName in skill.skills" :key="skillName" v-tooltip="{
                            content: skillName.info ? () => onSkillHover(skillName.name, skillName.info) : undefined,
                            loadingContent: 'Loading tooltip...',
                            theme: 'myTheme'
                        }" :class="{
                            highlighted: highlightedSkills.includes(skillName.name.toLowerCase()),
                            faded: highlightedSkills.length > 0 && !highlightedSkills.includes(skillName.name.toLowerCase())
                        }">
                            {{ skillName.name }}
                            <svg v-if="skillName.info" viewBox="0 0 6 6" aria-hidden="true">
                                <circle r="3" cx="3" cy="3"></circle>
                            </svg>
                        </li>
                    </SkillsSection>
                </ul>
            </HighlightContainer>
        </div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-8 order-1 mb-4 mb-sm-0">
            <HighlightContainer name="Experience">
                <CardsWithDate ref="JobCard" v-for="highlight in ExperienceData.highlights" :key="highlight"
                    :data="highlight" :date="highlight.date" :company="highlight.company" :place="highlight.place"
                    :selectedSkills="highlight.selectedSkills" @mouseenter="highlightSkills(highlight.selectedSkills)"
                    @mouseleave="clearHighlightedSkills">
                    <JobInfo :jobTitle="highlight.jobTitle" :previousJobTitle="highlight.previousJobTitle"
                        :department="highlight.department" :product="highlight.product" :cards="highlight.cards" />
                </CardsWithDate>
            </HighlightContainer>
        </div>
    </div>
</template>


<style scoped>
    .skills-stick {
        position: sticky;
        top: 2rem;

        transition: 0.15s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    .skills-container {
        background-color: var(--highlight-container-hover-color);
        box-shadow: inset 0 1px 0 0 var(--cards-border-color);
        padding: 1rem 1.5rem 1.2rem 1.5rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .skills-container li {
        background-color: var(--skills-background-color);
        color: var(--skills-text-color);
        line-height: 1.25rem;
        font-weight: 500;
        font-size: .75rem;
        padding: .25rem .75rem;
        border-radius: 16px;
        margin-top: .5rem;
        margin-right: .375rem;
        list-style: none;
        transition: 0.3s;
        position: relative;
    }

    .skills-container li.highlighted {
        box-shadow: 0px 0px 20px -6px var(--skills-text-color);
    }

    .skills-container li.faded {
        opacity: 0.5;
    }

    .skills-container li>svg {
        width: 0.5rem;
        height: 0.5rem;
        fill: var(--skills-text-color);
        position: absolute;
        right: 0;
        top: 0;
    }
</style>

<script>
    export default {
        mounted() {
            this.$nextTick(() => {
                const cards = this.$refs.JobCard;
                const accordionItems = Array.from(cards);

                accordionItems.forEach((item) => {
                    const elem = item.$el;
                    if (elem) {
                        //console.log(elem);
                        this.cardHoverTimer(elem);
                    }
                });
            });
        },
        methods: {
            highlightSkills(skill) {
                //console.log(skill);
                skill.forEach(s => this.highlightedSkills.push(s.trim().toLowerCase()));
            },
            clearHighlightedSkills() {
                this.highlightedSkills = [];
            },
            cardHoverTimer(element) {
                //console.log('job hovered')
                let timer;
                let cardTitle = '';

                if (element) {
                    cardTitle = element.querySelector('.job-title').textContent;
                }

                element.addEventListener('mouseenter', () => {
                    timer = setTimeout(() => {
                        //console.log("Hovered for 1.5 seconds");
                        //console.log(cardTitle);

                        this.$gtm.push({
                            event: 'Job Hover',
                            cardTitle: cardTitle,
                        });
                    }, 1500);
                });

                element.addEventListener('mouseleave', () => {
                    clearTimeout(timer);
                });

            },
            onSkillHover(skillName, description) {
                //console.log('skill hovered')
                console.log(description)

                this.$gtm.push({
                    event: 'Skill Card Hover',
                    cardTitle: skillName,
                });

                return description;
            },
        },
        data() {
            return {
                highlightedSkills: [],
            };
        },
    };
</script>