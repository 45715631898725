<script setup>
    import BlurLoadingContainer from '@/components/BlurLoadingContainer.vue';
</script>

<template>
    <div class="talk col-12 col-sm-12 col-md-6 col-lg-6">
        <RouterLink :to="'/talks/' + id" class="talk-link">
            <div class="talk-content">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                        clip-rule="evenodd">
                    </path>
                </svg>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 video-preview">
                        <!-- the height is different on mobile and pc, need a workaround -->
                        <BlurLoadingContainer ref="titleImageBlur" skeletonHeight="5rem">
                            <img :src="img" @load="$refs.titleImageBlur.loaded();" />
                        </BlurLoadingContainer>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 title mt-3 mt-lg-0">
                        <h4>{{ title }}</h4>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 description mt-3">
                        <p>{{ description }}</p>
                    </div>
                </div>
            </div>
        </RouterLink>
    </div>
</template>

<script>
    export default {
        mounted() {
            window.addEventListener("resize", this.resizeDescription);
            this.resizeDescription();
        },
        unmounted() {
            window.removeEventListener("resize", this.resizeDescription);
        },
        methods: {
            // The baddest code you've ever seen... but it works, fuck it
            resizeDescription() {
                const containers = document.querySelectorAll('.description');
                // Loop through each container
                Array.prototype.forEach.call(containers, (container) => {
                    const p = container.querySelector('p');
                    const divh = container.clientHeight;
                    // Check if the paragraph's height is taller than the container's height. If it is:
                    while (p.offsetHeight > divh) {
                        // add an ellipsis at the last shown space
                        p.textContent = p.textContent.replace(/\W*\s(\S)*$/, '...');
                    }
                })
            }
        },
        props: {
            id: String,
            video_id: String,
            img: String,
            title: String,
            description: String,
        },
    }
</script>

<style scoped>
    .talk-link {
        text-decoration: none;
    }

    .talk-link:hover {
        cursor: pointer;
    }

    .talk {
        position: relative;

        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s;

        border-radius: 8px;
    }

    .talk:hover {
        background-color: var(--cards-container-color);
        box-shadow: inset 0 1px 0 0 var(--cards-border-color);
    }

    .talk:hover~.talk {
        opacity: 0.5;
    }

    .talks:hover .talk:not(:hover) {
        opacity: 0.5;
    }

    .talk-content {
        padding: 1.3rem 1.3rem;
    }

    .talk-content svg {
        position: absolute;

        border-radius: 4px;
        background-color: var(--cards-container-color);

        top: .5rem;
        right: .5rem;

        width: 1.5rem;
        height: auto;

        flex-shrink: 0;

        transform: translate(-3px, 3px) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);

        fill: var(--main-text-color);
        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s;

    }

    .talk:hover svg {
        transform: translate(-1px, -1px) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
    }

    .video-preview img {
        aspect-ratio: 16/9;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
        user-select: none;
        pointer-events: none;
    }

    .title>h4 {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.4rem;
        color: var(--main-text-color);
        text-transform: capitalize;
        margin-bottom: .4rem;
        letter-spacing: .025em;
    }

    .description {
        height: 5rem;
        width: 100%;
        overflow: hidden;
    }

    .description>p {
        font-size: .85rem;
        line-height: 1rem;
        color: var(--second-text-color);
        margin: 0;
        letter-spacing: .025em;
    }

</style>