<template>
    <div class="highlights-container">
        <div class="highlight-description">
            <p>{{ name }}</p>
            <span></span>
        </div>
        <div class="highlights">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: String,
        }
    };
</script>

<style scoped>

    .highlights-container {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        margin-bottom: 1rem;
    }

    .highlights {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .highlight-description {
        margin-bottom: 0.5rem;
    }

    .highlight-description p {
        margin-bottom: 0.1rem;
        color: var(--highlight-title-color);
        text-transform: uppercase;
        font-weight: 700;
    }

    .highlight-description span {
        display: block;
        height: 2px;
        background: var(--highlight-title-color);
    }

</style>