<template>
    <div class="highlight">
        <h4>{{ title }}</h4>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
        }
    };
</script>

<style>
    .highlight {
        padding: 1.2rem 1.5rem;

        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s;

        border-radius: 8px;
    }

    .highlight h4 {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }

    .highlight p {
        font-size: .875rem;
        line-height: 1.5;
        color: var(--second-text-color);
        margin-bottom: 0;
    }

    .highlight:hover {
        background-color: var(--highlight-container-hover-color);
        box-shadow: inset 0 1px 0 0 var(--cards-border-color);
    }

    .highlight:hover~.highlight {
        opacity: 0.5;
    }

    .highlights:hover .highlight:not(:hover) {
        opacity: 0.5;
    }
</style>
