import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue';
import AboutView from '../views/AboutView.vue'
import ExperienceView from '../views/ExperienceView.vue'
import EducationView from '../views/EducationView.vue'
import TalksList from '@/views/TalksList.vue'
import TalkView from '@/views/TalkView.vue'
import NotFoundView from '@/views/NotFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'About',
    component: AboutView,
    meta: { title: 'Anton Bagin | About me' },
  },
  {
    path: '/experience',
    name: 'Experience',
    component: ExperienceView,
    meta: { title: 'Anton Bagin | Experience' },
  },
  {
    path: '/education',
    name: 'Education',
    component: EducationView,
    meta: { title: 'Anton Bagin | Education' },
  },
  {
    path: '/talks/',
    name: 'Talks',
    component: TalksList,
    meta: { title: 'Anton Bagin | Talks' },
  },
  {
    path: '/talks/:id',
    name: 'Talk',
    component: TalkView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundView,
    meta: { title: 'Anton Bagin | 404' },
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
})

const DEFAULT_TITLE = "Anton Bagin";
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router
