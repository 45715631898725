<template>
    <div class="row highlight" @mouseover="$emit('myMouseOver')" @mouseleave="$emit('myMouseLeave')">
        <div class="col-12 col-sm-12 col-md-12 col-lg-3 highlight-left">
            <p class="date">{{ date }}</p>
            <p class="company">{{ company }}</p>
            <h4 class="place mb-3">{{ place }}</h4>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-9">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            date: String,
            company: String,
            place: String,
        },
        emits: ['myMouseOver', 'myMouseLeave'],
    };
</script>


<style>
    .highlight {
        padding: 1.2rem 1.5rem;

        transition-property: all;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s;

        border-radius: 8px;

        margin: 0 !important;
    }

    .highlight:hover {
        background-color: var(--highlight-container-hover-color);
        box-shadow: inset 0 1px 0 0 var(--cards-border-color);
    }

    .highlight:hover~.highlight {
        opacity: 0.5;
    }

    .highlights:hover .highlight:not(:hover) {
        opacity: 0.5;
    }

    /* Highlight left data */

    .highlight-left .date {
        font-size: .75rem;
        font-weight: 600;
        line-height: 1.4rem;
        color: var(--main-text-color);
        text-transform: uppercase;
        margin-bottom: .3rem;
        letter-spacing: .025em;
    }

    .highlight-left .company {
        font-size: .75rem;
        font-weight: 600;
        line-height: 1rem;
        color: var(--main-text-color);
        text-transform: uppercase;
        margin: 0;
        letter-spacing: .025em;
    }

    .highlight-left .place {
        font-size: .75rem;
        font-weight: 600;
        line-height: 1rem;
        color: var(--second-text-color);
        margin: 0;
        text-transform: uppercase;
        letter-spacing: .025em;
    }

</style>
