<!-- u need to set ref variable to the component to use the command below -->
<!-- this.$refs.ytBlur.loaded(); -->

<template>
    <TransitionGroup name="fade">
        <div v-show="!loading" style="width: 100%; height: 100%;">
            <slot :loaded="loaded"></slot>
        </div>
        <div v-show="loading" class="loading-container">
            <div class="loading-animation"></div>
        </div>
    </TransitionGroup>
</template>

<script>
    export default {
        props: {
            skeletonHeight: {
                type: String,
                default: '100%'
            },
        },
        data() {
            return {
                loading: true,
            };
        },
        methods: {
            loaded() {
                console.log('loaded');
                this.loading = false;
            }
        }
    };
</script>

<style scoped>

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .loading-container {
        position: relative;
        width: 100%;
        height: v-bind(skeletonHeight);
        background-color: rgba(128, 128, 128, 0.2);
        overflow: hidden;
        border-radius: 12px;
    }

    .loading-animation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
        animation: loading 2.5s infinite;
        opacity: 0.25;
    }

    @keyframes loading {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(100%);
        }
    }
</style>